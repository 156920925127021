import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mt-4 d-flex flex-wrap",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "placeholder": "Suche",
      "outlined": "",
      "width": "50",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VSheet, {
    attrs: {
      "width": "100vw"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.SalePriceItems,
      "loading": _vm.loading,
      "search": _vm.search,
      "loading-text": "Verkaufspreise werden geladen"
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Verkaufspreise gefunden. ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine Verkaufspreise gefunden. ")];
      },
      proxy: true
    }, {
      key: `item.bf`,
      fn: function ({
        item
      }) {
        return [_c(VSimpleCheckbox, {
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.bf,
            callback: function ($$v) {
              _vm.$set(item, "bf", $$v);
            },
            expression: "item.bf"
          }
        })];
      }
    }, {
      key: `item.vbp`,
      fn: function ({
        item
      }) {
        return [_c(VSimpleCheckbox, {
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.vbp,
            callback: function ($$v) {
              _vm.$set(item, "vbp", $$v);
            },
            expression: "item.vbp"
          }
        })];
      }
    }, {
      key: `item.kv`,
      fn: function ({
        item
      }) {
        return [_c(VSimpleCheckbox, {
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.kv,
            callback: function ($$v) {
              _vm.$set(item, "kv", $$v);
            },
            expression: "item.kv"
          }
        })];
      }
    }, {
      key: `item.reha`,
      fn: function ({
        item
      }) {
        return [_c(VSimpleCheckbox, {
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.reha,
            callback: function ($$v) {
              _vm.$set(item, "reha", $$v);
            },
            expression: "item.reha"
          }
        })];
      }
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c('LBAButton', {
          staticClass: "ml-2 mb-2",
          attrs: {
            "buttonSize": 'small',
            "buttonType": 'grey'
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };