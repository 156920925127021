import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VCardText, {
    staticClass: "pt-0"
  }, [_c('div', [_c(VRow, {
    staticClass: "d-flex justify-space-between elevation-0 mt-3 pa-2"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "5",
      "lg": "8",
      "xl": "8"
    }
  }, [_c(VSimpleTable, {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        var _vm$baseInformations$, _vm$baseInformations$2, _vm$baseInformations$3, _vm$baseInformations$4, _vm$baseInformations$5, _vm$baseInformations$6;
        return [_c('tbody', [_c('tr', [_c('td', [_c('strong', [_vm._v("Artikelnummer:")])]), _c('td', [_vm._v(" " + _vm._s((_vm$baseInformations$ = _vm.baseInformations.key) === null || _vm$baseInformations$ === void 0 ? void 0 : _vm$baseInformations$.artNr) + " ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Kategorie:")])]), _c('td', [_vm._v(" " + _vm._s((_vm$baseInformations$2 = _vm.baseInformations.articleCategory) === null || _vm$baseInformations$2 === void 0 ? void 0 : _vm$baseInformations$2.description) + " ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Typ:")])]), _c('td', [_vm._v(" " + _vm._s(((_vm$baseInformations$3 = _vm.baseInformations.articleType) === null || _vm$baseInformations$3 === void 0 || (_vm$baseInformations$3 = _vm$baseInformations$3.key) === null || _vm$baseInformations$3 === void 0 ? void 0 : _vm$baseInformations$3.tpCode) + ' ' + ((_vm$baseInformations$4 = _vm.baseInformations.articleType) === null || _vm$baseInformations$4 === void 0 ? void 0 : _vm$baseInformations$4.description)) + " ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Gruppe:")])]), _c('td', [_vm._v(" " + _vm._s(((_vm$baseInformations$5 = _vm.baseInformations.articleGroup) === null || _vm$baseInformations$5 === void 0 || (_vm$baseInformations$5 = _vm$baseInformations$5.key) === null || _vm$baseInformations$5 === void 0 ? void 0 : _vm$baseInformations$5.lfdnr) + ' ' + ((_vm$baseInformations$6 = _vm.baseInformations.articleGroup) === null || _vm$baseInformations$6 === void 0 ? void 0 : _vm$baseInformations$6.description)) + " ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("StatistikCode:")])]), _c('td', [_vm._v(" " + _vm._s(_vm.baseInformations.articleStatCode) + " ")])])])];
      },
      proxy: true
    }])
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('div', [_vm.articleImages.length > 0 ? _c(VCarousel, {
    attrs: {
      "height": "230px",
      "contain": "",
      "show-arrows-on-hover": "",
      "hide-delimiter-background": "",
      "light": "",
      "delimiter-icon": "mdi-minus"
    }
  }, _vm._l(_vm.articleImages, function (item, i) {
    return _c(VCarouselItem, {
      key: i,
      attrs: {
        "contain": ""
      }
    }, [item.sm == null ? _c(VImg, {
      staticClass: "mr-0",
      attrs: {
        "height": "180px",
        "contain": "",
        "src": item.image
      }
    }) : _c(VImg, {
      staticClass: "mr-0",
      attrs: {
        "height": "160px",
        "contain": "",
        "src": item.image
      }
    }), item.sm != null ? _c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "width": "100%",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.sm) + " ")]) : _vm._e()], 1);
  }), 1) : _c(VImg, {
    staticClass: "mr-0",
    attrs: {
      "height": "200px",
      "contain": "",
      "src": require("../../assets/img/article-no-image.png")
    }
  })], 1)])], 1), _c(VRow, [_c(VCol, {
    staticClass: "d-flex justify-space-around flex-wrap",
    attrs: {
      "cols": "12"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "color": "primary",
      "disabled": "",
      "label": "gesperrt"
    },
    model: {
      value: _vm.checkboxes.artLocked,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artLocked", $$v);
      },
      expression: "checkboxes.artLocked"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Set",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artSet,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artSet", $$v);
      },
      expression: "checkboxes.artSet"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Krankenkassenposition",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artKrankenKasse,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artKrankenKasse", $$v);
      },
      expression: "checkboxes.artKrankenKasse"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Bestandsführung",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artInventoryManagement,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artInventoryManagement", $$v);
      },
      expression: "checkboxes.artInventoryManagement"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Saison",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artSaison,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artSaison", $$v);
      },
      expression: "checkboxes.artSaison"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Objektsverwaltung",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artObject,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artObject", $$v);
      },
      expression: "checkboxes.artObject"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "MengenID",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artQuantityID,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artQuantityID", $$v);
      },
      expression: "checkboxes.artQuantityID"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Produktionsartikel",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artProductionArticle,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artProductionArticle", $$v);
      },
      expression: "checkboxes.artProductionArticle"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Provision",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artCommission,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artCommission", $$v);
      },
      expression: "checkboxes.artCommission"
    }
  }), _c(VCheckbox, {
    staticClass: "pl-2 pr-2 float-left",
    attrs: {
      "color": "primary",
      "label": "Chargen",
      "disabled": ""
    },
    model: {
      value: _vm.checkboxes.artCharge,
      callback: function ($$v) {
        _vm.$set(_vm.checkboxes, "artCharge", $$v);
      },
      expression: "checkboxes.artCharge"
    }
  })], 1)], 1), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    staticClass: "d-flex flex-wrap",
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    staticClass: "pa-2",
    attrs: {
      "label": "Lagereinheit",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.InventoryManagement.storageUnit,
      callback: function ($$v) {
        _vm.$set(_vm.InventoryManagement, "storageUnit", $$v);
      },
      expression: "InventoryManagement.storageUnit"
    }
  }), _c(VTextField, {
    staticClass: "pa-2",
    attrs: {
      "label": "Objekttype",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.InventoryManagement.objectType,
      callback: function ($$v) {
        _vm.$set(_vm.InventoryManagement, "objectType", $$v);
      },
      expression: "InventoryManagement.objectType"
    }
  }), _c(VTextField, {
    staticClass: "pa-2",
    attrs: {
      "label": "Objekttypengruppe",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.InventoryManagement.objectTypeGroup,
      callback: function ($$v) {
        _vm.$set(_vm.InventoryManagement, "objectTypeGroup", $$v);
      },
      expression: "InventoryManagement.objectTypeGroup"
    }
  })], 1)], 1), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTabs, {
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.subTab,
      callback: function ($$v) {
        _vm.subTab = $$v;
      },
      expression: "subTab"
    }
  }, [_c(VTab, {
    key: "Informationen"
  }, [_vm._v(" Informationen ")]), _c(VTab, {
    key: "Sachmerkmale"
  }, [_vm._v(" Sachmerkmale ")]), _c(VTab, {
    key: "Verkaufspreise"
  }, [_vm._v(" Verkaufspreise ")]), _c(VTab, {
    key: "Einkaufspreise"
  }, [_vm._v(" Einkaufspreise ")]), _c(VTab, {
    key: "Artikelbewegungen"
  }, [_vm._v(" Artikelbewegungen ")]), _c(VTab, {
    key: "BeauftragtBestellt"
  }, [_vm._v(" Beauftragt/Bestellt ")]), _c(VTab, {
    key: "offAngebote"
  }, [_vm._v(" off. Angebote ")]), _vm.checkboxes.artInventoryManagement ? _c(VTab, {
    key: "Lagerbestände"
  }, [_vm._v(" Lagerbestände ")]) : _vm._e()], 1), this.visibleTabs.length >= 0 ? _c(VTabsItems, {
    model: {
      value: _vm.subTab,
      callback: function ($$v) {
        _vm.subTab = $$v;
      },
      expression: "subTab"
    }
  }, [_c(VTabItem, {
    key: "Informationen"
  }, [_c('div', [_c(VRow, {
    staticClass: "d-flex flex-wrap mt-4"
  }, [_c(VCol, {
    staticClass: "text-h6",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Bemerkung: "), _c('quill-editor', {
    ref: "myQuillEditor",
    staticStyle: {
      "height": "400px"
    },
    attrs: {
      "options": _vm.quillConfig,
      "disabled": ""
    },
    model: {
      value: _vm.baseInformations.comment,
      callback: function ($$v) {
        _vm.$set(_vm.baseInformations, "comment", $$v);
      },
      expression: "baseInformations.comment"
    }
  })], 1), _c(VCol, {
    staticClass: "text-h6",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Vermerk: "), _c('quill-editor', {
    ref: "myQuillEditor",
    staticStyle: {
      "height": "400px"
    },
    attrs: {
      "options": _vm.quillConfig,
      "disabled": ""
    },
    model: {
      value: _vm.baseInformations.note,
      callback: function ($$v) {
        _vm.$set(_vm.baseInformations, "note", $$v);
      },
      expression: "baseInformations.note"
    }
  })], 1)], 1)], 1)]), _c(VTabItem, {
    key: "Sachmerkmale"
  }, [_c('ArticleCharacteristics')], 1), _c(VTabItem, {
    key: "Verkaufspreise"
  }, [_c('ArticleSalePrices')], 1), _c(VTabItem, {
    key: "Einkaufspreise"
  }, [_c('ArticlePurchasePrice')], 1), _c(VTabItem, {
    key: "Artikelbewegungen"
  }, [_c('ArticleMovements')], 1), _c(VTabItem, {
    key: "BeauftragtBestellt"
  }, [_c('ArticleCommissionedOrdered')], 1), _c(VTabItem, {
    key: "offAngebote"
  }, [_c('ArticleOpen')], 1), _c(VTabItem, {
    key: "Lagerbestände"
  }, [_c('ArticleStock')], 1)], 1) : _vm._e()], 1)], 1)], 1)]), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };