import "core-js/modules/es.array.push.js";
import { mapState, mapGetters } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { DateTime } from 'luxon';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
export default {
  components: {
    ErrorDialog,
    LBAButton
  },
  props: {},
  data() {
    return {
      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      startdate: '',
      enddate: '',
      BeginDate: '',
      EndDate: '',
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'Dokument',
        value: 'document',
        sortable: true,
        align: 'start'
      }, {
        text: 'Datum',
        value: 'date',
        align: 'start'
      }, {
        text: 'Dok.-Nr.',
        value: 'docNr',
        align: 'start'
      }, {
        text: 'Kunde',
        value: 'customer',
        align: 'start'
      }, {
        text: 'Position',
        value: 'position',
        align: 'start'
      }, {
        text: 'Artikel SM',
        value: 'articleSM',
        align: 'start'
      }, {
        text: 'Lokation',
        value: 'location',
        align: 'start'
      }, {
        text: 'Menge',
        value: 'quantity',
        align: 'end'
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'end'
      }, {
        text: 'Eigenanteil',
        value: 'ownContribution',
        align: 'end'
      }],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    ...mapGetters(['getOpenOffers']),
    OpenOffersItemsFiltered() {
      console.warn(this.getOpenOffers);
      this.fillFilter();
      return this.OpenOffersItems.filter(item => item.show);
    },
    ...mapState({
      OpenOffersItems: state => state.articleDetails.openOffers,
      loading: state => state.articleDetails.openOffersLoader
    }),
    startDateFormat() {
      return this.startdate ? DateTime.fromISO(this.startdate).toLocaleString() : '';
    },
    endDateFormat() {
      return this.enddate ? DateTime.fromISO(this.enddate).toLocaleString() : '';
    }
  },
  watch: {
    '$route.params': 'init',
    menu1(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    menu2(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    startdate() {
      this.DateFilter();
      this.applyFilters();
    },
    enddate() {
      this.DateFilter();
      this.applyFilters();
    }
  },
  methods: {
    init() {
      this.loadOpenOffers();
      this.fillFilter();
    },
    openDocument(wfc_id, addressID) {
      this.$router.push({
        name: 'crmAddressDocumentDetailed',
        params: {
          lfdnr: addressID,
          wf_cid: wfc_id
        }
      });
    },
    openAddress(addressID) {
      this.$router.push({
        name: 'crmAddress',
        params: {
          lfdnr: addressID
        }
      });
    },
    ResetFilter() {
      this.startdate = null;
      this.enddate = null;
    },
    DateFilter() {
      var beginDateFormat = new Date(this.startdate);
      var endDateFormat = new Date(this.enddate);
      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum');
      }
    },
    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = '';
      }
      if (this.enddate == null || '') {
        this.enddate = '';
      }
    },
    // Load Data
    async loadOpenOffers() {
      try {
        await this.$store.dispatch('GET_OPEN_OFFERS', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Offers found');
        } else {
          this.failMessage = 'Beim Laden der offenen Angebote ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.OpenOffersItems.forEach(item => {
        item.show = true;
      });

      // Überprüfe die Filterkriterien und filtere die Daten
      this.OpenOffersItems.forEach(item => {
        var dateConverted = item.date.split('.').reverse().join('-');
        var dateFormat = new Date(dateConverted);
        var beginDateFormat = new Date(this.startdate);
        var endDateFormat = new Date(this.enddate);
        if (this.startdate && dateFormat < beginDateFormat || this.enddate && dateFormat > endDateFormat) {
          item.show = false;
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};