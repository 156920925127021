import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mt-4 d-flex flex-wrap",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c(VRow, {
    staticClass: "mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, [_c('LBAFilterExpansion', {
    attrs: {
      "text-color": 'default'
    },
    on: {
      "clear": function ($event) {
        return _vm.ResetFilter();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VRow, {
          staticClass: "mb-4 d-flex align-center",
          attrs: {
            "no-gutters": ""
          }
        }, [_c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VMenu, {
          ref: "menu1",
          staticClass: "mx-sm-1",
          attrs: {
            "close-on-content-click": false,
            "transition": "scale-transition",
            "offset-y": "",
            "max-width": "400px",
            "min-width": "auto"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VTextField, _vm._g(_vm._b({
                staticClass: "mx-sm-1",
                attrs: {
                  "label": "Startdatum",
                  "persistent-hint": "",
                  "append-icon": "mdi-calendar",
                  "color": "primary",
                  "clearable": "",
                  "outlined": "",
                  "value": _vm.startDateFormat
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.menu1,
            callback: function ($$v) {
              _vm.menu1 = $$v;
            },
            expression: "menu1"
          }
        }, [_c(VDatePicker, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.startdate,
            callback: function ($$v) {
              _vm.startdate = $$v;
            },
            expression: "startdate"
          }
        }, [_c(VSpacer), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              _vm.menu1 = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Abbrechen ")];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.menu1.save(_vm.startdate);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Ok ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VMenu, {
          ref: "menu2",
          attrs: {
            "close-on-content-click": false,
            "transition": "scale-transition",
            "offset-y": "",
            "max-width": "400px",
            "min-width": "auto"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VTextField, _vm._g(_vm._b({
                staticClass: "mx-sm-1",
                attrs: {
                  "label": "Enddatum",
                  "persistent-hint": "",
                  "append-icon": "mdi-calendar",
                  "color": "primary",
                  "clearable": "",
                  "outlined": "",
                  "value": _vm.endDateFormat
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.menu2,
            callback: function ($$v) {
              _vm.menu2 = $$v;
            },
            expression: "menu2"
          }
        }, [_c(VDatePicker, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.enddate,
            callback: function ($$v) {
              _vm.enddate = $$v;
            },
            expression: "enddate"
          }
        }, [_c(VSpacer), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              _vm.menu2 = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Abbrechen ")];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.menu2.save(_vm.enddate);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Ok ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c(VCol, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "items": _vm.alldocument,
            "label": "Dokumentart",
            "clearable": "",
            "outlined": "",
            "small-chips": ""
          },
          model: {
            value: _vm.document,
            callback: function ($$v) {
              _vm.document = $$v;
            },
            expression: "document"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "items": _vm.allsm,
            "label": "Sachmerkmal",
            "clearable": "",
            "outlined": "",
            "small-chips": ""
          },
          model: {
            value: _vm.sm,
            callback: function ($$v) {
              _vm.sm = $$v;
            },
            expression: "sm"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "items": _vm.allcustomer,
            "label": "Kunde",
            "clearable": "",
            "outlined": "",
            "small-chips": ""
          },
          model: {
            value: _vm.customer,
            callback: function ($$v) {
              _vm.customer = $$v;
            },
            expression: "customer"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "items": _vm.allsupplier,
            "label": "Lieferant",
            "clearable": "",
            "outlined": "",
            "small-chips": ""
          },
          model: {
            value: _vm.supplier,
            callback: function ($$v) {
              _vm.supplier = $$v;
            },
            expression: "supplier"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "15",
            "xs": "15",
            "sm": "15",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          staticClass: "mx-sm-1",
          attrs: {
            "items": _vm.allmovement,
            "return-object": "",
            "item-text": "fldDisplayBez",
            "label": "Bewegungsart",
            "clearable": "",
            "outlined": ""
          },
          model: {
            value: _vm.movement,
            callback: function ($$v) {
              _vm.movement = $$v;
            },
            expression: "movement"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "placeholder": "Suche",
      "outlined": "",
      "width": "50",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VSheet, {
    attrs: {
      "width": "100vw"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.CommissionedOrderedItemsFiltered,
      "loading": _vm.loading,
      "item-key": "docNr",
      "search": _vm.search,
      "loading-text": "Beauftragungen/Bestellungen werden geladen"
    },
    scopedSlots: _vm._u([this.$store.getters.userHasAppById(1) ? {
      key: "item.docNr",
      fn: function ({
        item
      }) {
        return [_c('a', {
          attrs: {
            "href": _vm.getDocumentLink(item.wf_cid, item.addressId),
            "target": "_blank"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDocument(item.wf_cid, item.addressId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.docNr) + " ")])];
      }
    } : null, this.$store.getters.userHasAppById(1) ? {
      key: "item.customerName",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openAddress(item.addressId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.customerName) + " ")])];
      }
    } : null, this.$store.getters.userHasAppById(1) ? {
      key: "item.supplierName",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openAddress(item.supplier);
            }
          }
        }, [_vm._v(" " + _vm._s(item.supplierName) + " ")])];
      }
    } : null, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Beauftragungen/Bestellungen gefunden. ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine Beauftragungen/Bestellungen gefunden. ")];
      },
      proxy: true
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c('LBAButton', {
          staticClass: "ml-2 mb-2",
          attrs: {
            "buttonSize": 'small',
            "buttonType": 'grey'
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };