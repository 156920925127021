import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { DateTime } from 'luxon';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
//import LBAFilterExpansion from '@/components/ui/expansion/LBAFilterExpansion.vue'

export default {
  components: {
    ErrorDialog,
    LBAButton
  },
  data() {
    return {
      i: null,
      startdate: '',
      enddate: '',
      document: null,
      sm: null,
      customer: null,
      supplier: null,
      movement: null,
      datefiltered: [],
      alldocument: [],
      allsm: [],
      allcustomer: [],
      allsupplier: [],
      allmovement: [],
      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      BeginDate: '',
      EndDate: '',
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'Dokument',
        value: 'document',
        sortable: true,
        align: 'start'
      }, {
        text: 'Datum',
        value: 'date',
        align: 'start'
      }, {
        text: 'L-Termin',
        value: 'ldate',
        align: 'start'
      }, {
        text: 'Dok.-Nr.',
        value: 'docNr',
        align: 'start'
      }, {
        text: 'Position',
        value: 'position',
        align: 'start'
      }, {
        text: 'Artikel-SM',
        value: 'articleSM',
        align: 'start'
      }, {
        text: 'Lokation',
        value: 'location',
        align: 'start'
      }, {
        text: 'Kunde',
        value: 'customerName',
        align: 'start'
      }, {
        text: 'Lieferant',
        value: 'supplierName',
        align: 'start'
      }, {
        text: 'L-Menge',
        value: 'lQuantity',
        align: 'end'
      }, {
        text: 'Rg.Menge',
        value: 'rqQuantity',
        align: 'end'
      }, {
        text: 'ME',
        value: 'me',
        align: 'start'
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'end'
      }, {
        text: 'Eigenanteil',
        value: 'ownContribution',
        align: 'end'
      }
      // { text: 'Lager nach Bewegung', value: 'StockAfterMovement', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    TransportItemsFiltered() {
      this.fillFilter();
      return this.TransportItems.filter(item => item.show);
    },
    startDateFormat() {
      return this.startdate ? DateTime.fromISO(this.startdate).toLocaleString() : '';
    },
    endDateFormat() {
      return this.enddate ? DateTime.fromISO(this.enddate).toLocaleString() : '';
    },
    filterableDocumentTypes() {
      return this.alldocument.map(document => document).sort((a, b) => a.localeCompare(b));
    },
    filterableCharacteristic() {
      return this.allsm.map(sm => sm).sort((a, b) => a.localeCompare(b));
    },
    filterableCustomers() {
      return this.allcustomer.map(customer => customer).sort((a, b) => a.localeCompare(b));
    },
    filterableSuppliers() {
      return this.allsupplier.map(supplier => supplier).sort((a, b) => a.localeCompare(b));
    },
    filterableMovements() {
      return this.allmovement.map(movement => movement).sort((a, b) => a.localeCompare(b));
    },
    ...mapState({
      TransportItems: state => state.articleDetails.transport,
      loading: state => state.articleDetails.transportLoader
    })
  },
  watch: {
    '$route.params': 'init',
    menu1(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    menu2(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    startdate() {
      this.DateFilter();
      this.applyFilters();
    },
    enddate() {
      this.DateFilter();
      this.applyFilters();
    },
    document() {
      this.DateFilter();
      this.applyFilters();
    },
    sm() {
      this.DateFilter();
      this.applyFilters();
    },
    customer() {
      this.DateFilter();
      this.applyFilters();
    },
    supplier() {
      this.DateFilter();
      this.applyFilters();
    },
    movement() {
      this.DateFilter();
      this.applyFilters();
    }
  },
  methods: {
    init() {
      this.loadTransport();
      this.fillFilter();
    },
    ResetFilter() {
      this.startdate = null;
      this.enddate = null;
      this.document = null;
      this.sm = null;
      this.customer = null;
      this.supplier = null;
      this.movement = null;
    },
    getDocumentLink(wfcId, addressID) {
      return `/crm/addresses/${addressID}/documents/${wfcId}`;
    },
    openDocument(wfcId, addressID) {
      this.$router.push(`/crm/addresses/${addressID}/documents/${wfcId}`);
    },
    openAddress(addressID) {
      this.$router.push(`/crm/addresses/${addressID}`);
    },
    DateFilter() {
      var beginDateFormat = new Date(this.startdate);
      var endDateFormat = new Date(this.enddate);
      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum');
      } else {
        for (var i = 0, length = this.$store.state.articleDetails.transport.length; i < length; i++) {
          var object = this.$store.state.articleDetails.transport[i];
          var dateConverted = object.date.split('.').reverse().join('-');
          var dateFormat = new Date(dateConverted);
          if (dateFormat < endDateFormat && dateFormat > beginDateFormat) {
            this.$store.commit('SET_DATEFILTER_TRANSPORT', {
              i: i,
              boolean: true
            });
          } else {
            this.$store.commit('SET_DATEFILTER_TRANSPORT', {
              i: i,
              boolean: false
            });
          }
        }
      }
    },
    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = '';
      }
      if (this.enddate == null || '') {
        this.enddate = '';
      }
      this.TransportItems.forEach(elem => {
        this.alldocument.push(elem.document);
        this.allsm.push(elem.articleSM);
        this.allcustomer.push(elem.customerName);
        this.allsupplier.push(elem.supplierName);
        if (elem.typeofMovement == 1) {
          this.allmovement.push('Verkauf');
        } else {
          this.allmovement.push('Einkauf');
        }
      });
    },
    // Load Data
    async loadTransport() {
      try {
        await this.$store.dispatch('GET_TRANSPORT', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Transports found');
        } else {
          this.failMessage = 'Beim Laden der Artikelbewegungen ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.TransportItems.forEach(item => {
        item.show = true;
      });
      console.log(this.TransportItems);

      // Überprüfe die Filterkriterien und filtere die Daten
      this.TransportItems.forEach(item => {
        var dateConverted = item.date.split('.').reverse().join('-');
        var dateFormat = new Date(dateConverted);
        var beginDateFormat = new Date(this.startdate);
        var endDateFormat = new Date(this.enddate);
        if (this.startdate && dateFormat < beginDateFormat || this.enddate && dateFormat > endDateFormat || this.document && item.document !== this.document || this.sm && item.articleSM !== this.sm || this.customer && item.customerName !== this.customer || this.supplier && item.supplierName !== this.supplier || this.movement && (this.movement === 'Verkauf' && item.typeofMovement === 2 || this.movement === 'Einkauf' && item.typeofMovement === 1)) {
          item.show = false;
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};