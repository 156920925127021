export default {
  formatEuro(value) {
    return Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(value)
  },
  format(value, currency) {
    if (currency === null) currency = ''

    if (currency === '') {
      return Intl.NumberFormat('de-DE', {}).format(value)
    } else {
      return Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency
      }).format(value)
    }
  },
  getCurrencySymbol(currency) {
    if (currency === null) return ''
    let locale = 'de-DE'
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    const parts = formatter.formatToParts(1)
    for (const part of parts) {
      if (part.type === 'currency') {
        return part.value
      }
    }
    return ''
  }
}
