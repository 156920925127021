import { mapState } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
export default {
  components: {
    ErrorDialog,
    LBAButton
  },
  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'Preisliste',
        value: 'prices',
        sortable: true,
        align: 'start'
      }, {
        text: 'gilt ab',
        value: 'validity',
        align: 'start'
      }, {
        text: 'Sachmerkmal',
        value: 'characteristic',
        align: 'start'
      }, {
        text: 'Verordner',
        value: 'prescriber',
        align: 'start'
      }, {
        text: 'Verkaufseinheit',
        value: 'salesUnit',
        align: 'start'
      }, {
        text: 'Faktor',
        value: 'factor',
        align: 'end'
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'end'
      }, {
        text: 'Bruttopreis',
        value: 'grossprice',
        align: 'end'
      }, {
        text: 'DB-1%',
        value: 'db1',
        align: 'end' /* Eventually new value name */
      }, {
        text: 'RA%',
        value: 'ra',
        align: 'end' /* Eventually new value name */
      }, {
        text: 'Währung',
        value: 'currency',
        align: 'start'
      }, {
        text: 'Preiseinheit',
        value: 'priceUnit',
        align: 'center'
      }, {
        text: 'BF',
        value: 'bf',
        align: 'center' /* Eventually new value name */
      },
      // Checkbox
      {
        text: 'reha',
        value: 'reha',
        align: 'center'
      } // Checkbox
      ],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    ...mapState({
      SalePriceItems: state => state.articleDetails.saleprices,
      loading: state => state.articleDetails.salepricesLoader
    })
  },
  methods: {
    init() {
      this.loadSalePrices();
    },
    async loadSalePrices() {
      try {
        await this.$store.dispatch('GET_SALEPRICES', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No SalePrices found');
        } else {
          this.failMessage = 'Beim Laden der Verkaufspreise ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    }
  },
  mounted() {
    this.init();
  }
};