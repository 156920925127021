import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { DateTime } from 'luxon';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
export default {
  components: {
    ErrorDialog,
    LBAButton
  },
  props: {},
  data() {
    return {
      i: null,
      startdate: '',
      enddate: '',
      document: null,
      sm: null,
      customer: null,
      supplier: null,
      movement: null,
      datefiltered: [],
      alldocument: [],
      allsm: [],
      allcustomer: [],
      allsupplier: [],
      allmovement: [],
      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'Dokument',
        value: 'document',
        sortable: true,
        align: 'start'
      }, {
        text: 'Datum',
        value: 'date',
        align: 'start'
      }, {
        text: 'Dok.-Nr.',
        value: 'docNr',
        align: 'start'
      }, {
        text: 'Position',
        value: 'position',
        align: 'start'
      }, {
        text: 'Artikel SM',
        value: 'articleSM',
        align: 'start'
      }, {
        text: 'Lokation',
        value: 'location',
        align: 'start'
      }, {
        text: 'Kunde',
        value: 'customerName',
        align: 'start'
      }, {
        text: 'Lieferant',
        value: 'supplierName',
        align: 'start'
      }, {
        text: 'Menge',
        value: 'quantity',
        align: 'end'
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'end'
      }, {
        text: 'Eigenanteil',
        value: 'ownContribution',
        align: 'end'
      }],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    startDateFormat() {
      return this.startdate ? DateTime.fromISO(this.startdate).toLocaleString() : '';
    },
    endDateFormat() {
      return this.enddate ? DateTime.fromISO(this.enddate).toLocaleString() : '';
    },
    CommissionedOrderedItemsFiltered() {
      this.fillFilter();
      return this.CommissionedOrderedItems.filter(item => item.show);
    },
    filterableDocumentTypes() {
      return this.alldocument.map(document => document).sort((a, b) => a.localeCompare(b));
    },
    filterableCharacteristic() {
      return this.allsm.map(sm => sm).sort((a, b) => a.localeCompare(b));
    },
    filterableCustomers() {
      return this.allcustomer.map(customer => customer).sort((a, b) => a.localeCompare(b));
    },
    filterableSuppliers() {
      return this.allsupplier.map(supplier => supplier).sort((a, b) => a.localeCompare(b));
    },
    filterableMovements() {
      return this.allmovement.map(movement => movement).sort((a, b) => a.localeCompare(b));
    },
    ...mapState({
      CommissionedOrderedItems: state => state.articleDetails.commissionedOrdered,
      loading: state => state.articleDetails.commissionedOrderedLoader
    })
  },
  watch: {
    '$route.params': 'init',
    menu1(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    menu2(val) {
      val && setTimeout(() => this.activePicker = 'YEAR');
    },
    startdate() {
      this.DateFilter();
      this.applyFilters();
    },
    enddate() {
      this.DateFilter();
      this.applyFilters();
    },
    document() {
      this.DateFilter();
      this.applyFilters();
    },
    sm() {
      this.DateFilter();
      this.applyFilters();
    },
    customer() {
      this.DateFilter();
      this.applyFilters();
    },
    supplier() {
      this.DateFilter();
      this.applyFilters();
    },
    movement() {
      this.DateFilter();
      this.applyFilters();
    }
  },
  methods: {
    init() {
      this.loadCommissionedOrdered();
      this.fillFilter();
    },
    ResetFilter() {
      this.startdate = null;
      this.enddate = null;
      this.document = null;
      this.sm = null;
      this.customer = null;
      this.supplier = null;
      this.movement = null;
    },
    openDocument(wf_cid, addressID) {
      this.$router.push({
        name: 'crmAddressDocumentDetailed',
        params: {
          lfdnr: addressID,
          wf_cid: wf_cid
        }
      });
    },
    getDocumentLink(wfc_id, addressID) {
      return `/crm/addresses/${addressID}/documents/${wfc_id}`;
    },
    openAddress(addressID) {
      this.$router.push(`/crm/addresses/${addressID}`);
    },
    DateFilter() {
      this.datefiltered = this.CommissionedOrderedItems;
      var beginDateFormat = new Date(this.startdate);
      var endDateFormat = new Date(this.enddate);
      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum');
      }
    },
    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = '';
      }
      if (this.enddate == null || '') {
        this.enddate = '';
      }
      this.CommissionedOrderedItems.forEach(elem => {
        this.alldocument.push(elem.document);
        this.allsm.push(elem.articleSM);
        this.allcustomer.push(elem.customerName);
        this.allsupplier.push(elem.supplierName);
        if (elem.typeofMovement == 1) {
          this.allmovement.push('Verkauf');
        } else {
          this.allmovement.push('Einkauf');
        }
      });
    },
    // Load Data
    async loadCommissionedOrdered() {
      try {
        await this.$store.dispatch('GET_COMMISSIONED_ORDERED', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Commission Ordered found');
        } else {
          this.failMessage = 'Beim Laden von Beauftragt/Bestellt ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    //Filter
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.CommissionedOrderedItems.forEach(item => {
        item.show = true;
      });

      // Überprüfe die Filterkriterien und filtere die Daten
      this.CommissionedOrderedItems.forEach(item => {
        var dateConverted = item.date.split('.').reverse().join('-');
        var dateFormat = new Date(dateConverted);
        var beginDateFormat = new Date(this.startdate);
        var endDateFormat = new Date(this.enddate);
        if (this.startdate && dateFormat < beginDateFormat || this.enddate && dateFormat > endDateFormat || this.document && item.document !== this.document || this.sm && item.articleSM !== this.sm || this.customer && item.customerName !== this.customer || this.supplier && item.supplierName !== this.supplier || this.movement && (this.movement === 'Verkauf' && item.typeofMovement === 2 || this.movement === 'Einkauf' && item.typeofMovement === 1)) {
          item.show = false;
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};